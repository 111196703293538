import { Dispatch } from 'redux'
import { get } from 'lodash'
import { actions as cartActions } from 'actions@app/cart'
import { IBook } from 'model@app/book'
import { IPaymentAndPriceSettings } from 'model@app/paymentAndPriceSettings'
import { IArchitecture } from 'model@app/architecture'
import Router from 'next/router'
import { CartType } from 'lib@app/types'
import { errTypes } from 'lib@app/errTypes'
import { fetcher } from 'lib@app/fetcher'

export const handleAddItemToCart = async (
  dispatch: Dispatch<any>,
  architecture: IArchitecture,
  book: IBook,
  paymentAndPriceSettings: IPaymentAndPriceSettings,
): Promise<string> => {
  const { bookId, page } = book
  const { serviceName } = architecture
  const { minimumPage, maximumPage } = paymentAndPriceSettings

  if (page < minimumPage) {
    return `作成可能な最低ページの${minimumPage}ページを下回っています。お手数をお掛けいたしますが、再作成をしてださい。`
  }
  if (page > maximumPage) {
    return `製本可能な最大ページの${maximumPage}ページを超えています。お手数をおかけしますが、${maximumPage}ページ以下になるように編集からページ削除するか、期間を短くして再作成してみてください。`
  }
  const response = await fetcher.post('/v1/cart', {
    bookId,
    serviceName,
    type: CartType.HAPPYLOGUE_PRINT,
  })

  if (
    get(response, 'data.err.code') ===
    errTypes.code.PRODUCT_ALREADY_EXIST_IN_CART
  ) {
    return errTypes.message[errTypes.code.PRODUCT_ALREADY_EXIST_IN_CART]
  }
  if (
    get(response, 'data.err.code') ===
    errTypes.code
      .PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_TOTAL_PAGE_IS_LESS_THAN_MINIMUM_NUMBER_OF_PAGES
  ) {
    return errTypes.message[
      errTypes.code
        .PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_TOTAL_PAGE_IS_LESS_THAN_MINIMUM_NUMBER_OF_PAGES
    ]
  }
  if (
    get(response, 'data.err.code') ===
    errTypes.code
      .PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_BECAUSE_OF_THE_LARGE_NUMBER_OF_PAGES_DELETE_THECONTENT_ON_THE_EDIT_PAGE
  ) {
    return errTypes.message[
      errTypes.code
        .PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_BECAUSE_OF_THE_LARGE_NUMBER_OF_PAGES_DELETE_THECONTENT_ON_THE_EDIT_PAGE
    ]
  }

  await dispatch(cartActions.storeItemsQuantity(response.data.totalQuantity))
  Router.push('/cart/view')

  return 'カートに追加しました'
}
