import React, { useEffect, useState } from 'react'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { IBookMeta } from 'model@app/bookMeta'
import { IUser } from 'model@app/user'
import { IPaymentAndPriceSettings } from 'model@app/paymentAndPriceSettings'
import {
  BookContentTypes,
  BookLayoutTypes,
  LoadingStatusType,
  ServiceNameType,
} from 'lib@app/types'

type Props = {
  failedBooks: IBookMeta[]
  paymentAndPriceSettings: IPaymentAndPriceSettings
  serviceName: ServiceNameType
  handleMoveToCreateBook: () => void
  user: IUser
}

export function FailedBookDialog(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isMinimumFailed, setIsMinimumFailed] = useState<boolean>(false)
  const [isMaximumFailed, setIsMaximumFailed] = useState<boolean>(false)
  useEffect(() => {
    if (!isEmpty(props.failedBooks)) {
      for (const failedBook of props.failedBooks) {
        if (
          failedBook.loadingStatus === LoadingStatusType.FAILED_MINIMUM_PAGE
        ) {
          setIsMinimumFailed(true)
        }
        if (
          failedBook.loadingStatus === LoadingStatusType.FAILED_MAXIMUM_PAGE
        ) {
          setIsMaximumFailed(true)
        }
      }
      setIsOpen(true)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.user])

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handleMoveToCreatePage = () => {
    props.handleMoveToCreateBook()
    handleCloseDialog()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>作成に失敗しました</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>
            {isMinimumFailed &&
              `先ほど作成されたフォトブックが最小ページ数の${props.paymentAndPriceSettings.minCreatedPage}ページを下回ったため作成ができませんでした。
              お手数をおかけいたしますが、再度コンテンツを増やしたフォトブックを作成してください。`}
            {props.serviceName === ServiceNameType.MINA &&
              props.failedBooks.filter(
                (book) => get(book, 'initialCreatedBookData.page') === 0,
              ).length > 0 &&
              'また、Instagram APIの24時間あたりのコンテンツ取得数の制限に引っかかっている可能性もあります。その場合は、明日以降に時間をおいてからお試しください。'}
          </div>
          <div>
            {isMaximumFailed &&
              `先ほど作成されたフォトブックが最大ページ数の${props.paymentAndPriceSettings.maxCreatedPage}ページを上回ったため作成ができませんでした。
              お手数をおかけいたしますが、再度コンテンツを減らしたフォトブックを作成してください。`}
          </div>
          {props.failedBooks.map((failedBook: IBookMeta, index: number) => (
            <p key={index}>
              <div>
                タイトル：{get(failedBook, 'initialCreatedBookData.title', '')}
              </div>
              <div>
                ページ数：{get(failedBook, 'initialCreatedBookData.page', '')}
              </div>
              <div>
                ページレイアウトタイプ：
                {get(failedBook, 'initialCreatedBookData.bookContentType') ===
                BookContentTypes.ALBUM
                  ? '写真アルバムタイプ'
                  : get(
                      failedBook,
                      'initialCreatedBookData.bookLayoutType',
                      '',
                    ) === BookLayoutTypes.SINGLE_SIDED
                  ? '１Pタイプ'
                  : '２Pタイプ'}
              </div>
              {get(failedBook, 'initialCreatedBookData.bookContentType', '') ===
              BookContentTypes.TIMELINE ? (
                <div>{`期間：${moment(
                  get(failedBook, 'initialCreatedBookData.startDate', ''),
                ).format('YYYY/MM/DD')}から${moment(
                  get(failedBook, 'initialCreatedBookData.endDate', ''),
                ).format('YYYY/MM/DD')}まで`}</div>
              ) : (
                <>
                  <div>{`アルバム数：${get(
                    failedBook,
                    'initialCreatedBookData.albumCount',
                    '',
                  )}`}</div>
                  <div>{`トータル写真枚数：${get(
                    failedBook,
                    'initialCreatedBookData.photoCount',
                    '',
                  )}`}</div>
                </>
              )}
              <div>ブックＩＤ：{get(failedBook, 'bookId', '')}</div>
            </p>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>キャンセル</Button>
        <Button onClick={handleMoveToCreatePage} color="primary">
          再作成
        </Button>
      </DialogActions>
    </Dialog>
  )
}
