import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import AppDrawer from 'containers@app/organisms/AppDrawer/AppDrawer'
import Home from 'containers@app/template/Home'

const HomePage = () => (
  <>
    <CssBaseline />
    <AppDrawer>
      <Home />
    </AppDrawer>
  </>
)

export default HomePage
