import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { Dispatch } from 'redux'
import { useSetRecoilState } from 'recoil'
import { useRouter } from 'next/router'
import { Theme } from '@mui/material/styles'
import { makeStyles, createStyles } from '@mui/styles'
import { errorLog } from 'lib@app/errorLog'
import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material'
import { actions as myPageActions } from 'actions@app/myPage'
import { handleAddItemToCart } from 'api@app/handleAddItemToCart'
import { useImageS3 } from 'hooks@app/useImageS3'
import { calculateUnitPrice } from 'lib@app/calculateUnitPrice'
import { fetcher } from 'lib@app/fetcher'
import { numberFormat } from 'lib@app/numberFormat'
import { IBook } from 'model@app/book'
import { ICart } from 'model@app/cart'
import { IPaymentAndPriceSettings } from 'model@app/paymentAndPriceSettings'
import { IPricingRange } from 'model@app/pricingRange'
import { IArchitecture } from 'model@app/architecture'
import { BookContentTypes, BookLayoutTypes } from 'lib@app/types'
import { snackbarState } from '@store/atoms/snackbar'
import { DeleteBookDialog } from './DeleteBookDialog'

export type IBookWithPurchases = IBook & { purchases: ICart }

type Props = {
  architecture: IArchitecture
  dispatch: Dispatch<any>
  book: IBookWithPurchases
  paymentAndPriceSettings: IPaymentAndPriceSettings
  priceRange: IPricingRange[]
  isLoading: boolean
}

export default function BookView(props: Props) {
  const classes = useStyles()
  const router = useRouter()
  const setSnackbar = useSetRecoilState(snackbarState)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [originalBook, setOriginalBook] = useState<IBook | null>(null)

  useEffect(() => {
    async function fetchOriginalBook() {
      if (props.book.originalBookId) {
        const response: { data: { book: IBook } } = await fetcher.get(
          '/v1/preview/fetch_book',
          {
            params: {
              bookId: props.book.originalBookId,
            },
          },
        )

        if (response.data?.book) {
          setOriginalBook(response.data.book)
        }
      } else {
        setOriginalBook(null)
      }
    }

    fetchOriginalBook()
  }, [props.book.originalBookId])

  const handleOpenDialog = () => {
    setIsOpen(true)
  }

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handleMoveToPreviewPage = () => {
    router.push('/preview/show/[bookId]', `/preview/show/${props.book.bookId}`)
  }

  const handleDeleteBook = async () => {
    try {
      setIsOpen(false)
      const deleteResponse = await fetcher.post('/v1/edit_book/delete_book', {
        bookId: props.book.bookId,
      })
      if (deleteResponse.data.err) {
        setSnackbar((prevState) => ({
          ...prevState,
          isOpen: true,
          message: deleteResponse.data.err.message,
        }))

        return
      }
      const response = await fetcher.get('/v1/my_page/home')
      await props.dispatch(myPageActions.fetchMyPageData(response.data.books))
      setSnackbar((prevState) => ({
        ...prevState,
        isOpen: true,
        message: '削除しました',
      }))
    } catch (err) {
      setSnackbar((prevState) => ({
        ...prevState,
        isOpen: true,
        message: 'エラーが発生しました',
      }))
    }
  }

  const handleCloneBook = async () => {
    setLoading(true)
    const response = await fetcher.post(
      '/v1/clone_book/create_a_copy_of_the_book',
      {
        bookId: props.book.bookId,
      },
    )
    if (response.status === 200) {
      setSnackbar((prevState) => ({
        ...prevState,
        isOpen: true,
        message: '本のコピーが完了しました',
      }))
    }
    setLoading(false)
  }

  const addItemToCart = async () => {
    try {
      const message = await handleAddItemToCart(
        props.dispatch,
        props.architecture,
        props.book,
        props.paymentAndPriceSettings,
      )
      setSnackbar((prevState) => ({
        ...prevState,
        isOpen: true,
        message: message || 'カートに追加しました',
      }))
    } catch (err) {
      errorLog(err)
    }
  }

  const bookPrice = calculateUnitPrice(
    props.book,
    props.priceRange,
    props.paymentAndPriceSettings,
  )
  const photoUrl = encodeURIComponent(
    `photos/${props.book.bookId}/coverphotos/cover.jpg`,
  )
  let coverImage
  const count =
    props.book.bookContentType === BookContentTypes.TIMELINE
      ? props.book.feedCount
      : props.book.photoCount

  const source = useImageS3(
    `${process.env.NEXT_PUBLIC_API_SERVER_URL}/v1/preview/display_image/?photoUrl=${photoUrl}`,
  )

  switch (true) {
    case props.book.loaded && count === 0:
      coverImage = '/images/sample_cover.png'
      break
    case props.book.loaded && count > 0:
      coverImage = source
      break
    case !props.book.loaded:
      coverImage = '/images/loading.png'
      break
    default:
  }

  if (props.isLoading === true) {
    return (
      <div className={classes.cardContainer}>
        <Skeleton variant="rectangular" width={800} height={570} />
      </div>
    )
  }

  return (
    <div className={classes.cardContainer}>
      <Card>
        <CardMedia className={classes.coverStyle} image={coverImage} />
        <Divider />
        {props.book.loaded && (
          <CardActions>
            <Button
              className={classes.viewButtonStyle}
              variant="outlined"
              onClick={handleMoveToPreviewPage}
            >
              サンプルを見る
            </Button>
          </CardActions>
        )}
        <CardHeader className={classes.contentStyle} title={props.book.title} />
        <CardContent className={classes.contentStyle}>
          {props.book.originalBookId && (
            <Alert
              severity="info"
              action={
                originalBook ? (
                  <Button
                    onClick={() =>
                      router.push(`/preview/show/${originalBook.bookId}`)
                    }
                  >
                    {originalBook?.title}
                  </Button>
                ) : (
                  <Button>-</Button>
                )
              }
            >
              オリジナルの本から作成されたコピー本です
            </Alert>
          )}
          <Typography variant="body2">
            作成日：
            {moment(props.book.createdTime).format('YYYY年MM月DD日')}
          </Typography>

          <Typography variant="body2">
            ページレイアウトタイプ：
            {props.book.bookContentType === BookContentTypes.TIMELINE
              ? props.book.bookLayoutType === BookLayoutTypes.SINGLE_SIDED
                ? '１Ｐタイプ'
                : '２Ｐタイプ'
              : '写真アルバムタイプ'}
          </Typography>
          <Typography variant="body2">
            コンテンツタイプ：
            {props.book.bookContentType === BookContentTypes.TIMELINE
              ? 'タイムライン'
              : 'アルバム'}
          </Typography>
          {props.book.bookContentType === BookContentTypes.TIMELINE && (
            <Typography variant="body2">
              期間：
              {moment(props.book.startDate).format('YYYY/MM/DD')} から
              {moment(props.book.endDate).format('YYYY/MM/DD')} まで
            </Typography>
          )}
          {props.book.loaded && (
            <Typography variant="body2">
              {props.book.bookContentType === BookContentTypes.TIMELINE
                ? `${props.book.feedCount}投稿 `
                : `アルバム${props.book.albumCount}個 写真${props.book.photoCount}枚 `}
              {`${props.book.page}ページ ${numberFormat(bookPrice)}円`}
            </Typography>
          )}
        </CardContent>
        {props.book.loaded ? (
          <CardActions className={classes.buttonStyle}>
            <Button variant="outlined" onClick={handleOpenDialog}>
              削除
            </Button>
            <Button variant="outlined" onClick={handleCloneBook}>
              本のコピーを作成する
            </Button>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="primary" />
            </Backdrop>
            <Button variant="outlined" onClick={addItemToCart}>
              {get(props.book, 'purchases.length', 0) > 0
                ? '再購入する'
                : 'カートに入れる'}
            </Button>
          </CardActions>
        ) : (
          <CardActions className={classes.buttonStyle}>
            <Button>作成中</Button>
          </CardActions>
        )}
      </Card>
      <DeleteBookDialog
        isOpen={isOpen}
        handleCloseDialog={handleCloseDialog}
        handleDeleteBook={handleDeleteBook}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      marginTop: '20px',
      margin: '0 auto',
    },
    coverStyle: {
      position: 'relative',
      paddingTop: '100%',
      maxWidth: '100%',
    },
    viewButtonStyle: {
      margin: '0 auto',
    },
    contentStyle: {
      textAlign: 'right',
    },
    buttonStyle: {
      float: 'right',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
)
