import { IBook } from 'model@app/book'
import { IPaymentAndPriceSettings } from 'model@app/paymentAndPriceSettings'
import { IPricingRange } from 'model@app/pricingRange'

export const calculateUnitPrice = (
  book: IBook,
  priceRange: IPricingRange[],
  priceSettings: IPaymentAndPriceSettings,
) => {
  if (!Array.isArray(priceRange)) {
    return priceSettings?.initialPrice
  }

  let max = 0
  let totalPrice = 0
  for (const price of priceRange) {
    if (book.page < price.pagesFrom) {
      continue
    }
    max =
      price.pagesTo !== 0 && book.page > price.pagesTo
        ? price.pagesTo
        : book.page
    totalPrice += (max - price.pagesFrom + 1) * price.perPagePrice
  }

  return totalPrice + priceSettings?.initialPrice
}
