import { AxiosResponse } from 'axios'
import { ApiClient } from '@infrastructure/api-client'
import { errorLog } from 'lib@app/errorLog'
import {
  MyBooks,
  Permission,
  Redirect,
  Album,
  CreateBookOptions,
  CreateBookResponse,
} from './book.type'

export class BookApi {
  private readonly api: ApiClient

  constructor() {
    this.api = new ApiClient()
  }

  async getBooks(): Promise<{ data: MyBooks }> {
    try {
      const response = await this.api.get<MyBooks>('/v1/my_page/home')

      return { data: response.data }
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async checkPermission(
    requiredPermissions: string[],
  ): Promise<AxiosResponse<Permission>> {
    try {
      const response = await this.api.post<
        Permission,
        { requiredPermissions: string[] }
      >('/v1/facebook_data/checkPermission', { requiredPermissions })

      return response
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async getAlbums(isGranted: boolean): Promise<AxiosResponse<Album>> {
    try {
      if (!isGranted) {
        return { data: { albums: [] } } as AxiosResponse<Album>
      }
      const response = await this.api.get<Album, { isGranted: boolean }>(
        '/v1/create_book/get_album_data',
      )

      return response
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async bookRedirect(type: string): Promise<AxiosResponse<Redirect>> {
    try {
      const response = await this.api.post<Redirect, { type: string }>(
        '/v1/facebook_data/redirect',
        { type },
      )

      return response
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async checkAccessToken(): Promise<AxiosResponse<Permission>> {
    try {
      const response = await this.api.post<Permission>(
        '/v1/mina/create_book/checkAccessTocken',
      )

      return response
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async minaCreateBook(): Promise<AxiosResponse<Redirect>> {
    try {
      const response = await this.api.post<Redirect>(
        '/v1/mina/create_book/initialize',
      )

      return response
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async createBook(
    serviceApiPath: string,
    serviceProvider: string,
    options: CreateBookOptions,
  ): Promise<AxiosResponse<CreateBookResponse>> {
    try {
      const response = await this.api.post<
        CreateBookResponse,
        CreateBookOptions
      >(`${serviceApiPath}/${serviceProvider}/start_loading`, options)

      return response
    } catch (err) {
      errorLog(err)
      throw err
    }
  }
}
