import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSetRecoilState } from 'recoil'
import Router, { useRouter } from 'next/router'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Button, Card, CardHeader, CardMedia, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { BookApi } from '@infrastructure/book'
import { IUser } from 'model@app/user'
import { IArchitecture } from 'model@app/architecture'
import { IStore } from 'reducers@app/index'

import { useImageS3 } from 'hooks@app/useImageS3'
import { ServiceNameType, ServiceProviderType } from 'lib@app/types'
import { fetcher } from 'lib@app/fetcher'

import type { RootState } from '@store/configureStore'
import { snackbarState } from '@store/atoms/snackbar'
import { FailedBookDialog } from './FailedBookDialog'
import BookView from './BookView'

const CardMediaS3 = (props: { userId: string }) => {
  const classes = useStyles()

  const source = useImageS3(
    `${
      process.env.NEXT_PUBLIC_API_SERVER_URL
    }/v1/preview/display_image/?time=${new Date().getTime()}&photoUrl=${encodeURIComponent(
      `profile/${props.userId}/picture.jpg`,
    )}`,
  )

  if (!source) {
    return null
  }

  return <CardMedia className={classes.thumb} image={source} />
}

export default function Home() {
  const classes = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const setSnackbar = useSetRecoilState(snackbarState)
  const architecture = useSelector<RootState, IArchitecture>(
    (state: IStore) => state.architecture,
  )
  // TODO should fix downcast
  const user = useSelector<RootState, IUser>(
    (state) => state.user.user as IUser,
  )

  const { data: myBooks, isLoading } = useQuery(['my_page'], () =>
    new BookApi().getBooks(),
  )

  useEffect(() => {
    if (router.query.register_complete) {
      setSnackbar((prevState) => ({
        ...prevState,
        isOpen: true,
        message: '登録完了しました',
      }))
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [router.query.register_complete])

  const handleMoveToCreateBook = async () => {
    if (architecture.serviceProvider === ServiceProviderType.FACEBOOK) {
      Router.push('/create_book/choose_book_type')
    } else {
      const response = await fetcher.post('/v1/mina/create_book/initialize')
      if (response.status === 200 && response.data?.url) {
        Router.push(response.data.url)
      }
    }
  }

  return (
    <>
      {myBooks?.data?.failedBooks && (
        <FailedBookDialog
          failedBooks={myBooks?.data?.failedBooks}
          paymentAndPriceSettings={myBooks?.data?.paymentAndPriceSettings}
          serviceName={architecture.serviceName}
          handleMoveToCreateBook={handleMoveToCreateBook}
          user={user}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleMoveToCreateBook}
        fullWidth={true}
      >
        フォトブックを作成
      </Button>
      {user && (
        <Card className={classes.card}>
          <CardMediaS3 userId={user.userId} />
          <CardHeader
            className={classes.headerStyle}
            subheader={
              <Typography variant="body2" align="center">{`${
                user.serviceName === ServiceNameType.MINA
                  ? `@${user.instagramUserName}` || user.name
                  : user.name
              }さんのフォトブック`}</Typography>
            }
          />
        </Card>
      )}
      {myBooks?.data?.books?.map((book) => (
        <BookView
          key={book.bookId}
          architecture={architecture}
          book={book}
          dispatch={dispatch}
          paymentAndPriceSettings={myBooks?.data?.paymentAndPriceSettings}
          priceRange={myBooks?.data?.priceRange}
          isLoading={isLoading}
        />
      ))}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '65px',
      marginTop: theme.spacing(3),
    },
    card: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
    },
    thumb: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      alignSelf: 'center',
    },
    headerStyle: {
      margin: '0 auto',
    },
  }),
)
