import React from 'react'
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'

type Props = {
  isOpen: boolean
  handleCloseDialog(): any
  handleDeleteBook(): any
}

export const DeleteBookDialog = (props: Props) => (
  <Dialog
    open={props.isOpen}
    onClose={props.handleCloseDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>本当にフォトブックを削除しますか？</DialogTitle>
    <DialogActions>
      <Button onClick={props.handleCloseDialog} color="primary">
        キャンセル
      </Button>
      <Button onClick={props.handleDeleteBook} color="primary" autoFocus={true}>
        削除
      </Button>
    </DialogActions>
  </Dialog>
)
