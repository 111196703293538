import { ActionType, createAction } from 'typesafe-actions'
import { ActionTypes } from 'constants@app'
import { IBook } from 'model@app/book'
import { IBookMeta } from 'model@app/bookMeta'

export const actions = {
  fetchMyPageData: createAction(ActionTypes.FETCH_BOOKS, (resolve): any => {
    return (mypage: IBook[]) => resolve(mypage)
  }),
  fetchFailedBooks: createAction(
    ActionTypes.FETCH_DELETED_BOOKS,
    (resolve): any => {
      return (failedBooks: IBookMeta[]) => resolve(failedBooks)
    },
  ),
}
export type IMypageAction = ActionType<typeof actions>
